import api from './index';

export default {
  answer: {
    answer(questionId, data) {
      return api.post(`/api/questions/${questionId}/answer`, data);
    },
    recordTiming(questionId, data) {
      return api.post(`/api/questions/${questionId}/record-timing`, data);
    },
    regenerateFeedback(questionId, answerToQuestionId, userId) {
      return api.post(`/api/questions/${questionId}/regenerate-feedback`, {answerToQuestionId, userId});
    },
  },
};
