<template>
  <div class="px-10">
    <h1 class="my-8 text-3xl">
      Añadir pregunta
    </h1>
    <div class="flex gap-5">
      <label
        for="file"
        class=""
      >
        Subir pregunta para {{ topic.name }}
      </label>
      <input
        id="file"
        type="file"
        name="file"
        class="mb-2 w-full appearance-none rounded border px-3 py-2 text-gray-700 shadow"
        @change="handleFileUpload($event, topic.id)"
      >
    </div>
  </div>
  <div class="bg-gray-100 p-6">
    <transition-group
      name="fade"
      tag="div"
      enter-active-class="transition-opacity duration-10"
      enter-from-class="opacity-0"
      leave-active-class="transition-opacity duration-10"
      leave-to-class="opacity-0"
    >
      <div
        v-for="question in questions"
        :key="question.id"
        class="mx-4 mx-auto mb-4 rounded bg-white p-4 shadow"
      >
        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <span
              v-if="question.difficulty <= 0.3"
              class="mr-3 inline-flex items-center justify-center rounded-lg bg-green-400 px-3 py-1 text-white"
              title="Esta pregunta es de nivel fácil"
              style="cursor: default;"
            >facil</span>
            <span
              v-if="question.difficulty > 0.3 && question.difficulty <= 0.6"
              class="mr-3 inline-flex items-center justify-center rounded-lg bg-yellow-400 px-3 py-1 text-white"
              title="Esta pregunta es de nivel media"
              style="cursor: default;"
            >media</span>
            <span
              v-if="question.difficulty > 0.6 "
              class="mr-3 inline-flex items-center justify-center rounded-lg bg-red-400 px-3 py-1 text-white"
              title="Esta pregunta es de nivel dificil"
              style="cursor: default;"
            >dificil</span>
          </div>
          <span class="mr-4 flex-1 p-2">
            <template v-if="question.editing">
              <h1 class="my-1">Pregunta: </h1>
              <textarea
                v-model="question.question"
                class="w-full rounded border border-gray-300 p-1"
              />
              <h1 class="mt-2">Respuesta: </h1>
              <textarea
                v-model="question.answer"
                class="mt-2 w-full rounded border border-gray-300 p-1"
                rows="4"
              />
              <div class="mt-2">
                <label
                  for="difficulty"
                  class="block text-sm font-medium text-gray-700"
                >Dificultad</label>
                <select
                  id="difficulty"
                  v-model="question.difficulty"
                  name="difficulty"
                  class="mt-1 block rounded-md border border-gray-300 p-2"
                >
                  <option
                    disabled
                    selected
                  >Selecciona una dificultad</option>
                  <option value="0.25">Fácil 😀</option>
                  <option value="0.5">Medio 😐</option>
                  <option value="0.75">Difícil 😟</option>
                </select>
              </div>
            </template>
            <template v-else>
              <div
                :id="`markdown_question_${question.id}`"
                v-html="renderMarkdown(question.question)"
              />
              <h1>Respuesta:</h1>
              <div class="mx-10 my-2 rounded border text-gray-600">{{ question.answer }}</div>
            </template>
          </span>
          <div class="flex items-center">
            <template v-if="!question.editing">
              <button
                class="duration-50 mr-3 rounded bg-teal-500 px-6 py-2 text-white transition ease-in-out hover:scale-110"
                @click="generateQuestion(question.id)"
              >
                Generar pregunta
              </button>
              <button
                :class="`px-4 py-2 border border-blue-300 text-white rounded mr-3 transition ease-in-out
                 hover:scale-110 duration-50 ${acceptedQuestions.includes(question.id) ? 'bg-green-500' : 'bg-blue-400'}`"
                @click="acceptedQuestions.push(question.id)"
              >
                {{ acceptedQuestions.includes(question.id) ? 'Aceptada' : 'Aceptar' }}
              </button>
              <button
                class="duration-50 mr-1 rounded px-3 py-2 text-white transition ease-in-out hover:scale-110"
                @click="startEditing(question)"
              >
                <i class="fas fa-pencil-alt text-teal-500" />
              </button>
              <button
                class="duration-50 mr-1 rounded px-3 py-2 text-white transition ease-in-out hover:scale-110"
                @click="deleteQuestion(question.id)"
              >
                <i class="fas fa-trash text-red-500" />
              </button>
            </template>
            <button
              v-if="question.editing"
              class="duration-50 mr-3 rounded bg-teal-500 px-4 py-2 text-white transition ease-in-out hover:scale-110"
              @click="saveQuestion(question)"
            >
              Guardar
            </button>
            <button
              v-if="question.editing"
              class="duration-50 mr-3 rounded bg-red-400 px-4 py-2 text-white transition ease-in-out hover:scale-110"
              @click="cancelEditing(question)"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import topicsApi from '@/api/topics';
import { marked } from 'marked';

export default {
  data() {
    return {
      topic: {},
      questions: [],
      acceptedQuestions: [],
    };
  },
  async mounted() {
    const topicResponse = await topicsApi.getById(this.$route.params.topicId);
    this.topic = topicResponse.data.data;
    const questionsResponse = await topicsApi.questions.getAll(this.$route.params.topicId);
    this.questions = questionsResponse.data.data;
    this.acceptedQuestions = this.questions.map(question => question.id);
    this.sortQuestionsByDifficulty();
    this.renderAllMarkdown();
  },

  methods: {
    async deleteQuestion(questionId) {
      await topicsApi.questions.delete(this.$route.params.topicId, questionId);
      this.questions = this.questions.filter(question => question.id !== questionId);
    },

    async handleFileUpload(event, topicId) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      await topicsApi.questions.upload(topicId, formData);

      const questionsResponse = await topicsApi.questions.getAll(this.$route.params.topicId);
      this.questions = questionsResponse.data.data;
      this.acceptedQuestions = this.questions.map(question => question.id);
      this.sortQuestionsByDifficulty();
      this.renderAllMarkdown();
    },

    async addQuestion() {
      this.questions.push({
        id: Date.now(), question: '', answer: '',
        difficulty: 0.0, type: 'yes_no', enableAdditionalComment: false,
        isRequired: false,
      });
    },

    async updateQuestion(questionId) {
      await topicsApi.questions.update(
        this.$route.params.topicId, questionId, this.questions.find(question => question.id === questionId),
      );
    },

    async generateQuestion(questionId) {
      const response = await topicsApi.questions.generate(this.$route.params.topicId, questionId);
      const newQuestion = response.data.data;

      const index = this.questions.findIndex(question => question.id === questionId);
      if (index !== -1) {
        this.questions.splice(index + 1, 0, newQuestion);
      }
      document.getElementById(`markdown_question_${newQuestion.id}`).innerHTML = marked(newQuestion.question);
    },
    sortQuestionsByDifficulty() {
      this.questions.sort((a, b) => {
        if (a.id == this.questions[0].id) return -1;
        if (b.id == this.questions[0].id) return 1;

        return b.difficulty - a.difficulty;
      });
    },

    async acceptQuestion(questionId) {
      // to do
    },

    async saveQuestion(question) {
      question.editing = false;
      await this.updateQuestion(question.id);
    },

    cancelEditing(question) {
      // Modo edicion OFF
      question.question = question.originalQuestion;
      question.answer = question.originalAnswer;
      question.editing = false;
    },

    startEditing(question) {
      // Modo edicion ON
      question.originalQuestion = question.question;
      question.originalAnswer = question.answer;
      question.editing = true;
    },

    renderMarkdown(markdown){
      return marked(markdown);
    },

    renderAllMarkdown(){
      this.$nextTick(() => {
        this.questions.forEach(question => {
          const markdownContent = document.getElementById(`markdown_question_${question.id}`);
          if (markdownContent) {
            markdownContent.innerHTML = marked(question.question);
          }
        });
      });
    },
  },
};
</script>

<style>
 code {
  @apply text-balance;
}
</style>

