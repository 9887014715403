import api from './index';

export default {
  topics: {
    update(unitId, id, data) {
      return api.patch(`/api/units/${unitId}/topics/${id}`, data);
    },
    create(unitId, data) {
      return api.post(`/api/units/${unitId}/topics`, data);
    },
    delete(unitId, id) {
      return api.delete(`/api/units/${unitId}/topics/${id}`);
    },
  },
};
