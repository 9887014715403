import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';

import TeacherCourseCreate from '@/views/Teacher/Course/Create.vue';
import TeacherCourseEdit from '@/views/Teacher/Course/Edit.vue';
import TeacherCourseFiles from '@/views/Teacher/Course/Files.vue';
import TeacherCourseIndex from '@/views/Teacher/Course/Index.vue';
import TeacherCourseShow from '@/views/Teacher/Course/Show.vue';
import TeacherCourseUnitEdit from '@/views/Teacher/Course/Unit/Edit.vue';
import TeacherCourseUnitTopicQuestionIndex from '@/views/Teacher/Course/Unit/Topic/Question/Index.vue';

import StudentCourseIndex from '@/views/Student/Course/index.vue';
import StudentCourseShow from '@/views/Student/Course/show.vue';
import StudentTopicShow from '@/views/Student/Topic/show.vue';

const router = createRouter({
  mode: 'history',
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
    },
    {
      name: 'teacher',
      path: '/teacher',
      children: [
        {
          path: '/teacher/courses',
          name: 'teacher.course.index',
          component: TeacherCourseIndex,
        },
        {
          path: '/teacher/courses/:id/edit',
          name: 'teacher.course.edit',
          component: TeacherCourseEdit,
        },
        {
          path: '/teacher/courses/:id/files',
          name: 'teacher.course.files',
          component: TeacherCourseFiles,
        },
        {
          path: '/teacher/courses/create',
          name: 'teacher.course.create',
          component: TeacherCourseCreate,
        },
        {
          path: '/teacher/courses/:id',
          name: 'teacher.course.show',
          component: TeacherCourseShow,
        },
        {
          path: '/teacher/courses/:id/units/edit',
          name: 'teacher.course.unit.edit',
          component: TeacherCourseUnitEdit,
        },
        {
          path: '/teacher/courses/:courseId/units/:unitId/topics/:topicId/questions',
          name: 'teacher.course.unit.topic.question.index',
          component: TeacherCourseUnitTopicQuestionIndex,
        },
      ],
    },
    {
      name:'student',
      path: '/student',

      children: [
        {
          path: '/student/course/:id',
          name: 'student.course.show',
          component: StudentCourseShow,
        },
        {
          path : '/student/courses',
          name : 'student.course.index',
          component : StudentCourseIndex,
        },
        {
          path: '/student/course/:courseId/unit/:unitId/topic/:topicId',
          name: 'student.course.unit.topic.show',
          component: StudentTopicShow,
          props: true,
        },
      ],
    },
  ],
});

export default router;
