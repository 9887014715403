<template>
  <div class="m-10 rounded-lg bg-white p-8 shadow">
    <div class="text-lg">
      <label class="mb-2 font-bold text-gray-700">Curso</label>
      <p class="px-3 py-2 text-base leading-tight text-gray-700">
        {{ course?.name }}
      </p>
    </div>
    <div
      v-for="unit in units"
      :key="unit.id"
      class="pl-4"
    >
      <div
        v-if="!unit._deleted"
        v-id
      >
        <div class="mb-4 font-semibold text-gray-800">
          Unidad
        </div>

        <div class="container mb-6 space-y-6">
          <div class="flex flex-row">
            <label class="mr-4 font-semibold text-gray-800">Nombre</label>
            <input
              v-model="unit.name"
              type="text"
              class="text-lg focus:ring-0"
            >
          </div>
          <div class="flex flex-row">
            <label class="mr-4 font-semibold text-gray-800">Descripción</label>
            <textarea
              v-model="unit.description"
              class="bg-transparent focus:ring-0"
            />
          </div>
        </div>
        <div class="flex-none font-semibold">
          Temas
        </div>
        <div class="flex flex-row flex-wrap justify-center">
          <div
            v-for="(topic, index) in unit.topics"
            :key="topic.id"
            class="m-2 rounded-xl border shadow"
          >
            <div
              v-if="!topic._deleted"
              class="flex grow flex-col space-y-4 p-4"
            >
              <div class="mb-4">
                <label class="font-semibold text-gray-800">Tema {{ index + 1 }}</label>
              </div>
              <div>
                <label class="mr-4 font-semibold text-gray-800">Nombre</label>
                <input
                  v-model="topic.name"
                  type="text"
                  class="w-2/3 bg-transparent text-lg font-semibold focus:ring-0"
                >
              </div>
              <div>
                <label class="mr-4 font-semibold text-gray-800">
                  Descripción
                </label>
                <textarea
                  v-model="topic.description"
                  class="w-2/3 bg-transparent focus:ring-0"
                />
              </div>
              <button
                class="text-red-500"
                @click="topic._deleted = true"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
        <div class="flex justify-center space-x-6">
          <button
            class="mt-4 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
            @click="createTopic(unit._id )"
          >
            Create Topic
          </button>
          <button
            class="text-red-500"
            @click="unit._deleted = true"
          >
            Delete
          </button>
        </div>
        <div class="flex justify-center space-x-6">
          <button
            class="mt-4 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
            @click="createUnit"
          >
            Create Unit
          </button>
          <button
            class="mt-4 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
            @click="submit"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { nanoid } from 'nanoid';

import coursesApi from '@/api/courses';

import unitsApi from '@/api/units';

export default {
  data() {
    return {
      course: {},
      units: [],
    };
  },
  async mounted() {
    this.course = (await coursesApi.getById(this.$route.params.id, {
      withUnits: true,
      withUnitsTopics: true,
    })).data.data;

    this.units = this.course.units.map(unit => ({
      ...unit,
      _id: nanoid(),
      topics: unit.topics.map(topic => ({
        ...topic,
        _id: nanoid(),
      })),
    }));
  },
  methods: {
    createUnit() {
      this.units.push({
        name: 'New Unit',
        description: 'New Description',
        topics: [],
        _id: nanoid(),
      });
    },
    createTopic(unitNanoid) {
      this.units = this.units.map(u => {
        if (u._id === unitNanoid) {
          u.topics.push({
            name: 'New Topic',
            description: 'New Description',
            _id: nanoid(),
          });
        }

        return u;
      });
    },
    async submit() {
      this.units.forEach(async unit => {
        if (unit._deleted) {
          unit.topics.forEach(async topic => {
            if (topic.id) {
              await unitsApi.topics.delete(unit.id, topic.id);
            }
          });

          await coursesApi.units.delete(this.course.id, unit.id);


          return;
        }
        if (unit.id === undefined) {
          const createdUnit = (await coursesApi.units.create(this.course.id, unit)).data.data;

          unit.topics.forEach(topic => {
            this.handleTopicChange(createdUnit, topic);
          });

          return;
        }

        await coursesApi.units.update(this.course.id, unit.id, unit);

        unit.topics.forEach(topic => {
          this.handleTopicChange(unit, topic);
        });
      });
    },
    handleTopicChange(unit, topic) {
      if (topic._deleted) {
        unitsApi.topics.delete(unit.id, topic.id);
      } else if (topic.id) {
        unitsApi.topics.update(unit.id, topic.id);
      } else {
        unitsApi.topics.create(unit.id, topic);
      }
    },
  },
};
</script>
