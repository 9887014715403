<template>
  <div class="m-10 rounded-lg bg-white p-8 shadow">
    <div class="container mb-6 space-y-6 text-lg">
      <div>
        <h1 class="mb-2 text-xl font-bold text-gray-700">
          Curso
        </h1>
        <div class="px-3 py-2 text-base leading-tight text-gray-700">
          {{ course?.name }}
        </div>
      </div>
      <div>
        <label class="block font-bold text-gray-700">Description:</label>
        <p class="px-3 py-2 text-base leading-tight text-gray-700">
          {{ course?.description }}
        </p>
      </div>
      <div>
        <router-link
          :to="{name: 'teacher.course.unit.edit', params: { id: course.id }}"
          class="mb-6 flex justify-center"
        >
          <div>
            Modificar Unidades/Temas
          </div>
        </router-link>
        <router-link
          :to="{name: 'teacher.course.files', params: { id: course.id }}"
          class="flex justify-center"
        >
          <div>
            Subir archivos
          </div>
        </router-link>
      </div>
    </div>
    <div class="mb-6">
      <label class="mb-2 block text-lg font-bold text-gray-700">Units:</label>
      <div
        v-for="unit in course.units"
        :key="unit.id"
        class="container mb-5 space-y-5 border p-5 shadow"
      >
        <div>
          <p class="text-gray-700">
            Unidad: {{ unit.name }}
          </p>
          <p class="text-gray-700">
            Description: {{ unit.description }}
          </p>
        </div>
        <label class="mb-2 block font-bold text-gray-700">Topics:</label>
        <div class="grid justify-center gap-4 sm:grid-cols-2 md:grid-cols-4">
          <div
            v-for="topic in unit.topics"
            :key="topic.id"
            class="flex flex-col"
          >
            <p class="text-gray-700">
              {{ topic.name }}
            </p>
            <p class="text-gray-700">
              {{ topic.description }}
            </p>
            <router-link
              :to="{name: 'teacher.course.unit.topic.question.index',
                    params: {courseId: course.id, unitId: unit.id, topicId: topic.id}}"
              class="content-start"
            >
              Añadir preguntas
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import coursesApi from '@/api/courses';

export default {
  data() {
    return {
      course: {},
    };
  },
  async mounted() {
    this.course = (await coursesApi.getById(this.$route.params.id, {
      withUnits: true,
      withUnitsTopics: true,
    })).data.data;
  },
};
</script>
