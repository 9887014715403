<template>
  <main class="flex min-h-screen flex-col items-center justify-center bg-gray-100 p-4">
    <div>
      <h1 class="mb-4 text-4xl font-bold">
        Página con la lista de cursos
      </h1>
    </div>

    <div class="m-2 mt-6 grid grid-cols-1 justify-center gap-4 sm:grid-cols-2 md:grid-cols-3">
      <div
        v-for="course in sortedCourses"
        :key="course.id"
        class="flex max-w-sm flex-col rounded-lg bg-white shadow-lg"
      >
        <div class="flex grow flex-col px-6 py-4">
          <div class="mb-2 text-xl font-bold">
            {{ course.name }}
          </div>
          <p class="grow text-base text-gray-700">
            {{ course.description }}
          </p>
        </div>
        <div class="flex justify-end px-6 py-2">
          <RouterLink
            :to="{ name: 'student.course.show', params: { id: course.id } }"
            class="inline-block rounded-full bg-[#34a193] px-4 py-2 font-bold text-white hover:bg-[#34a192a2]"
          >
            Ver curso
          </RouterLink>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import coursesApi from '@/api/courses';

export default {
  data() {
    return {
      courses: [],
    };
  },
  computed: {
    sortedCourses() {
      //Ordenar alfabeticamente los cursos (para presentarlos en la vista)
      return [...this.courses].sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  async mounted() {
    const response = await coursesApi.getAll();
    this.courses = response.data.data;
  },
};
</script>
