import api from './index';

export default {
  getById(id) {
    return api.get(`/api/topics/${id}`);
  },
  awsfiles: {
    create(topicId, data) {
      return api.post(`/api/topics/${topicId}/awsfiles`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    getAll(topicId) {
      return api.get(`/api/topics/${topicId}/awsfiles`);
    },
  },
  questions: {
    getAll(topicId) {
      return api.get(`/api/topics/${topicId}/questions`);
    },
    generate(topicId, questionId, params) {
      return api.get(`/api/topics/${topicId}/questions/${questionId}/generate`, {
        params,
      });
    },
    upload(topicId, data) {
      return api.post(`/api/topics/${topicId}/questions/upload`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    delete(topicId, questionId) {
      return api.delete(`/api/topics/${topicId}/questions/${questionId}`);
    },
    create(topicId, data) {
      return api.post(`/api/topics/${topicId}/questions`, data);
    },
    update(topicId, questionId, data) {
      return api.put(`/api/topics/${topicId}/questions/${questionId}`, data);
    },
    getForStudent(topicId, studentId) {
      return api.get(`/api/topics/${topicId}/questions/get-for-student`, {
        params: {
          studentId,
        },
      });
    },
    judge(topicId, questionId, data) {
      return api.post(`/api/topics/${topicId}/questions/${questionId}/judge`, data);
    },
  },
};
