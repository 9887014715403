<template>
  <main class="min-h-screen bg-gray-100">
    <div class="flex h-full flex-col space-x-0 md:flex-row md:space-x-10">
      <div
        class="ml-3 mr-8 mt-5 w-full shrink-0 md:ml-3 md:mt-32 md:w-64"
        style="height: fit-content;"
      >
        <div class="fixed rounded-lg bg-[#34a193] p-4 text-white md:w-64">
          <h2 class="text-2xl font-bold">
            Unidades
          </h2>
          <ul class="mt-4">
            <li
              v-for="unit in units"
              :key="unit.id"
              class="mb-2"
            >
              <div
                class="flex cursor-pointer items-center justify-between"
                @click="toggleUnit(unit.id)"
              >
                <span class="font-semibold">{{ unit.name }}</span>
                <span>
                  <i
                    v-if="expandedUnits.includes(unit.id)"
                    class="fas fa-chevron-up"
                  />
                  <i
                    v-else
                    class="fas fa-chevron-down"
                  />
                </span>
              </div>
              <ul
                v-if="expandedUnits.includes(unit.id)"
                class="ml-4"
              >
                <li
                  v-for="topic in unit.topics"
                  :key="topic.id"
                >
                  <a
                    class="cursor-pointer text-white hover:text-white"
                    @click="showTopic(unit, topic)"
                  >
                    {{ topic.name }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div class="mt-5 flex w-full flex-col md:ml-5">
        <h1 class="mb-4 text-4xl font-bold">
          Bienvenido al curso {{ course.name }}
        </h1>
        <div
          v-if="selectedUnit && selectedTopic"
          class="mr-8 mt-10 rounded-lg bg-white p-6 shadow-md"
        >
          <h1 class="mb-4 text-4xl font-bold">
            {{ `${selectedUnit.name} > ${selectedTopic.name}` }}
          </h1>
          <p>{{ selectedTopic.description }}</p>
        </div>
        <div
          v-if="selectedUnit && selectedTopic"
          class="mr-8 mt-10 rounded-lg bg-white p-6 shadow-md"
        >
          <h1 class="mb-4 text-4xl font-bold">
            Contenido
          </h1>
          <div v-html="topicContent" />
        </div>
        <div
          v-if="selectedUnit && selectedTopic"
          class="mr-8 mt-10 flex flex-col gap-4 rounded-lg bg-white p-6 shadow-md"
        >
          <h1 class="mb-4 text-4xl font-bold">
            Pregunta
          </h1>

          <div>
            <select v-model="selectedStudent">
              <option value="11">
                Estudiante buen rendimiento
              </option>
              <option value="12">
                Estudiante mal rendimiento
              </option>
            </select>
          </div>

          <button
            type="button"
            class="inline-block rounded-full bg-[#34a193] px-4 py-2 font-bold text-white hover:bg-[#34a192a2]"
            @click="getQuestion"
          >
            Obtener pregunta
          </button>
          <div class="flex flex-col gap-4 py-5">
            <div
              id="markdown-question"
              class="flex flex-col gap-4"
              v-html="currentQuestionContent"
            />
            <textarea
              v-model="currentAnswer"
              class="h-60 w-full rounded-lg border border-gray-300 p-2"
              placeholder="Escribe tu respuesta aquí"
              @click="startSendResponse"
            />
          </div>
          <button
            type="button"
            class="inline-block rounded-full bg-[#34a193] px-4 py-2 font-bold text-white hover:bg-[#34a192a2]"
            :disabled="finalAnswer?.isCorrect"
            @click="isCorrect"
          >
            Enviar
          </button>

          <div class="flex flex-col gap-5">
            <div class="rounded border px-3 py-5">
              <div
                v-if="finalAnswer"
              >
                {{ finalAnswer?.isCorrect ? 'Correcto' : 'Incorrecto' }}
              </div>
            </div>
            <div class="flex flex-col gap-3 rounded border px-3 py-5">
              <div>
                <b v-if="localFeedback">
                  Feedback de la pregunta
                </b>
                {{ localFeedback?.feedback }}
              </div>
              <div>
                <b v-if="localFeedback && localFeedback?.contenidosPasados?.length">
                  Te sugerimos revisar los siguientes contenidos
                </b>
                <div v-if="localFeedback?.contenidosPasados?.length">
                  {{ localFeedback?.contenidosPasados }}
                </div>
              </div>
              <div class="rounded bg-gray-200 p-3">
                <b v-if="localFeedback">
                  Analisis de uso interno no mostrado al estudiante
                </b>
                {{ localFeedback?.analisis }}
              </div>
              <div class="flex justify-end">
                <button
                  type="button"
                  class="px-4 pt-5 text-sm font-bold text-gray-500"
                  @click="regenerateFeedback()"
                >
                  REGENERAR
                </button>
              </div>
            </div>
            <div class="rounded border px-3 py-5">
              {{ generalFeedback }}
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        @click="nextTopic"
      >
        Siguiente tema
      </button>
    </div>
  </main>
</template>

<script>
import coursesApi from '@/api/courses';
import topicsApi from '@/api/topics';
import { marked } from 'marked';
import questionsApi from '@/api/questions';

export default {
  data() {
    return {
      course: {},
      units: [],
      expandedUnits: [],
      selectedUnit: null,
      selectedTopic: null,

      selectedStudent: 11,

      currentQuestion: {},
      currentAnswer: '',

      finalAnswer: null,

      localFeedback: null,
      generalFeedback: null,

      output: null,
      topicContent: '', // Added this line
      currentQuestionContent: '', // Added this line

      timings: {
        startReadQuestion: null,
        startSendResponse: null,
      },
    };
  },
  async mounted() {
    const response = await coursesApi.getById(this.$route.params.id, {
      withUnitsTopics: true,
    });
    this.course = response.data.data;
    this.units = this.course.units;
  },
  methods: {
    startReadQuestion() {
      //send read topic timing



      this.timings.startReadQuestion = new Date();
    },
    startSendResponse() {
      if (!this.timings.startReadQuestion) {
        return;
      }

      if (this.finalAnswer)
        return;

      this.timings.startSendResponse = new Date();

      questionsApi.answer.recordTiming(this.currentQuestion.id, {
        'user_id': this.selectedStudent,
        'timing': (this.timings.startSendResponse - this.timings.startReadQuestion) / 1000,
        'timing_type': 'read_question',
      });
    },
    toggleUnit(unitId) {
      if (this.expandedUnits.includes(unitId)) {
        this.expandedUnits = this.expandedUnits.filter(id => id !== unitId);
      } else {
        this.expandedUnits.push(unitId);
      }
    },
    async showTopic(unit, topic) {
      // Clear the current question and answer
      this.currentQuestion = {};
      this.currentAnswer = '';

      // Clear the feedback and output
      this.output = null;
      this.localFeedback = null;
      this.generalFeedback = null;

      // Clear the markdown-question content
      this.currentQuestionContent = '';

      this.selectedUnit = unit;
      this.selectedTopic = topic;

      this.topicContent = ''; // Clear previous topic content

      const files = (await topicsApi.awsfiles.getAll(this.selectedTopic.id)).data;

      const topicContentUrl = files?.data[0]?.url;

      if (topicContentUrl) {
        const topicContentResponse = await fetch(topicContentUrl);
        this.topicContent = marked.parse(await topicContentResponse.text());
      } else {
        this.topicContent = 'No hay contenido disponible';
      }
    },

    async getQuestion() {
      const response = await topicsApi.questions.getForStudent(this.selectedTopic.id, this.selectedStudent);
      this.currentQuestion = response.data.data;
      this.currentAnswer = this.currentQuestion.answer;

      this.currentQuestionContent = marked.parse(this.currentQuestion.question);

      this.output = null;
      this.localFeedback = null;
      this.generalFeedback = null;
      this.finalAnswer = null;

      this.startReadQuestion();
    },

    async regenerateFeedback()
    {
      const questionId = this.currentQuestion.id;
      const answerToQuestionId = this.finalAnswer.id;

      const response = await questionsApi.answer.regenerateFeedback(
        questionId,
        answerToQuestionId,
        this.selectedStudent);

      this.localFeedback = response.data.feedback.localFeedback;

      this.generalFeedback = response.data.feedback.generalFeedback;
    },
    async nextTopic() {
      const nextTopicIndex = this.selectedUnit.topics.findIndex(topic => topic.id === this.selectedTopic.id) + 1;

      if (nextTopicIndex < this.selectedUnit.topics.length) {
        this.showTopic(this.selectedUnit, this.selectedUnit.topics[nextTopicIndex]);
      } else {
        const nextUnitIndex = this.units.findIndex(unit => unit.id === this.selectedUnit.id) + 1;
        if (nextUnitIndex < this.units.length) {
          this.showTopic(this.units[nextUnitIndex], this.units[nextUnitIndex].topics[0]);
        }
      }
    },

    async isCorrect() {
      if (!this.timings.startSendResponse) {
        this.timings.startSendResponse = new Date() - 10000;
      }

      questionsApi.answer.recordTiming(this.currentQuestion.id, {
        'user_id': this.selectedStudent,
        'timing': (new Date()- this.timings.startSendResponse) / 1000,
        'timing_type': 'send_response',
      });

      const response = await questionsApi.answer.answer(this.currentQuestion.id, {
        "answer": this.currentAnswer,
        "user_id": this.selectedStudent,
      });

      this.finalAnswer = response.data.answer;

      this.localFeedback = response.data.feedback.localFeedback;
      this.generalFeedback = response.data.feedback.generalFeedback;

      if (this.finalAnswer.isCorrect) {
        this.timings = {
          startReadQuestion: null,
          startSendResponse: null,
        };
      }
    },
  },
};
</script>

<style>
#markdown-question h1 {
  @apply text-2xl font-bold;
}
</style>
