import api from './index';

export default {
  getAll(params) {
    return api.get('/api/courses', {params});
  },
  getById(id, params) {
    return api.get(`/api/courses/${id}`, {params});
  },
  update(id, data) {
    return api.patch(`/api/courses/${id}`, data);
  },
  delete(id) {
    return api.delete(`/api/courses/${id}`);
  },
  create(data) {
    return api.post('/api/courses', data);
  },
  units: {
    update(courseId, id, data) {
      return api.patch(`/api/courses/${courseId}/units/${id}`, data);
    },
    create(courseId, data) {
      return api.post(`/api/courses/${courseId}/units`, data);
    },
    delete(courseId, id) {
      return api.delete(`/api/courses/${courseId}/units/${id}`);
    },
  },
  topics: {
    update(courseId, id, data) {
      return api.patch(`/api/courses/${courseId}/topics/${id}`, data);
    },
    create(courseId, data) {
      return api.post(`/api/courses/${courseId}/topics`, data);
    },
    delete(courseId, id) {
      return api.delete(`/api/courses/${courseId}/topics/${id}`);
    },
    getById(id) {
      return api.get(`/api/topics/${id}`);
    },
  },
};
