<template>
  <div class="flex h-screen flex-col items-center justify-center">
    <h1 class="mb-8 text-3xl font-bold">
      TESTER
    </h1>
    <div class="rounded-lg bg-white p-8 shadow-md">
      <div class="flex w-full flex-col justify-center gap-4">
        <router-link
          :to="{name: 'student.course.index'}"
          class="rounded-lg bg-teal-500 px-8 py-4 text-lg font-semibold text-white hover:bg-teal-600 focus:bg-teal-600 focus:outline-none"
        >
          Ver cursos (estudiante)
        </router-link>
        <router-link
          :to="{name: 'teacher.course.index'}"
          class="rounded-lg bg-teal-500 px-8 py-4 text-lg font-semibold text-white hover:bg-teal-600 focus:bg-teal-600 focus:outline-none"
        >
          Ver Cursos (Profesor)
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  methods: {},
};
</script>
