<template>
  <form class="mx-auto my-10 max-w-lg rounded-lg bg-white p-8 shadow">
    <div class="mb-6">
      <label
        for="name"
        class="mb-2 block text-sm font-bold text-gray-700"
      >Name:
      </label>
      <input
        id="name"
        v-model="name"
        type="text"
        name="name"
        class="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
      >
    </div>
    <div class="mb-6">
      <label
        for="description"
        class="mb-2 block text-sm font-bold text-gray-700"
      >Description:</label>
      <textarea
        id="description"
        v-model="description"
        name="description"
        class="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
      />
    </div>
    <button
      type="button"
      class="focus:shadow-outline rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700 focus:outline-none"
      @click="submit"
    >
      Submit
    </button>
  </form>
</template>
<script>

import coursesApi from '@/api/courses';


export default {
  data() {
    return {
      name: '',
      description: '',
    };
  },
  methods: {
    async submit() {
      const course = await coursesApi.create({
        name: this.name,
        description: this.description,
      });

      this.$router.push({
        name: 'teacher.course.show',
        params: { id: course.data.data.id },
      });
    },
  },
};

</script>
