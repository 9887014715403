<template>
  <main class="bg-gray-100">
    <div class="flex flex-col justify-center">
      <h1 class="mb-4 text-4xl font-bold">
        Estás en la unidad {{ unit?.name }}
      </h1>
      <p>{{ unit?.description }}</p>
      <h2 class="mb-2 text-2xl font-bold">
        {{ topic?.name }}
      </h2>
      <p>{{ topic?.description }}</p>
      <div id="content" />
    </div>
  </main>
</template>
<script>

import topicsApi from '@/api/topics';
import { marked } from 'marked';


export default {
  data() {
    return {
      topic: {},
      topicContent: '',
    };
  },
  async mounted(){
    const response = (await topicsApi.getById(this.$route.params.topicId)).data;
    this.topic = response.data;

    const files = (await topicsApi.awsfiles.getAll(this.$route.params.topicId)).data;

    const topicContentUrl = files.data[0].url;

    const topicContentResponse = await fetch(topicContentUrl);

    this.topicContent = await topicContentResponse.text();

    document.getElementById('content').innerHTML =
      marked.parse(this.topicContent);
  },
};
</script>
