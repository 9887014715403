<template>
  <div class="m-10 rounded-lg bg-white p-8 shadow">
    <h1 class="mb-6 text-2xl font-bold text-gray-700">
      Cursos
    </h1>
    <div class="flex flex-row justify-center">
      <div
        v-for="course in courses"
        :key="course.id"
        class="mb-5 mr-5 w-full rounded-xl border p-5 shadow sm:w-1/2 md:w-1/3 lg:w-1/5"
      >
        <div class="grid gap-y-2 text-gray-700">
          <div class="font-bold">
            Curso
          </div>
          <div>
            {{ course.name }}
          </div>
          <div class="font-bold">
            Descripción
          </div>
          <div>
            {{ course.description }}
          </div>
          <router-link :to="{name: 'teacher.course.show', params: {id: course.id}}">
            <div class="text-black">
              Ir al Curso
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import coursesApi from '@/api/courses';

export default {
  data() {
    return {
      courses: [],
    };
  },
  async mounted() {
    this.courses = (await coursesApi.getAll()).data.data;
  },
};
</script>
