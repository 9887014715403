<template>
  <div class="mx-48 my-10 rounded-lg bg-white p-8 shadow">
    <div class="mx-16">
      <div class="mb-6">
        <label class="mb-2 block text-sm font-bold text-gray-700">Name:</label>
        <p class="w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow">
          {{ course?.name }}
        </p>
      </div>
      <div class="mb-6">
        <label class="mb-2 block text-sm font-bold text-gray-700">Description:</label>
        <p class="w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow">
          {{ course?.description }}
        </p>
      </div>
      <div class="mb-6">
        <label class="mb-2 block text-sm font-bold text-gray-700">Units:</label>
        <div
          v-for="unit in course.units"
          :key="unit.id"
        >
          <p class="mb-4 text-3xl font-bold">
            {{ unit.name }}
          </p>
          <div
            v-for="topic in unit.topics"
            :key="topic.id"
          >
            <label
              for="file"
              class="px-1 py-2 text-gray-700"
            >
              Upload file for {{ topic.name }}
            </label>
            <ul class="mb-2 list-disc rounded bg-gray-100 p-4 pl-5">
              <li
                v-for="file in topic.files"
                :key="file.id"
                class="mb-1 ml-4"
              >
                <a
                  :href="file.url"
                  target="_blank"
                  class="text-blue-500 underline hover:text-blue-700"
                >
                  {{ file.path }}
                </a>
              </li>
            </ul>
            <input
              id="file"
              type="file"
              name="file"
              class="mb-2 w-full appearance-none rounded border px-3 py-2 text-gray-700 shadow"
              @change="handleFileUpload($event, topic.id)"
            >
            <div class="flex justify-end">
              <button
                class="mb-2 rounded bg-cyan-500 px-4 py-2 font-bold text-white hover:bg-cyan-700"
                @click="getFilesForTopic(topic.id)"
              >
                Get files for topic
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div />
  </div>
</template>

<script>
import coursesApi from '@/api/courses';
import topicsApi from '@/api/topics';

export default {
  data() {
    return {
      course: {},
      topics: [],
    };
  },
  async mounted() {
    this.course = (await coursesApi.getById(this.$route.params.id, {
      withUnits: true,
      withUnitsTopics: true,
    })).data.data;

    this.topics = this.course.units.reduce((acc, unit) => {
      acc.push(...unit.topics);
      return acc;
    }, []);
  },
  methods: {
    async handleFileUpload(event, topicId) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      await topicsApi.awsfiles.create(topicId, formData);

      this.getFilesForTopic(topicId);
    },
    async getFilesForTopic(topicId) {
      const files = (await topicsApi.awsfiles.getAll(topicId)).data.data;

      this.topics = this.topics.map((topic) => {
        if (topic.id === topicId) {
          topic.files = files;
        }
        return topic;
      });
    },
  },
};
</script>
